import React from 'react';
import { graphql } from 'gatsby';

import {
  BlogLayout,
  PostCard,
  Pagination,
  SubscribeBanner,
} from '../../components/blog';

interface BlogIndexProps {
  data: any;
  pageContext: PageContext;
}

interface PageContext {
  pageNumber: number;
  humanPageNumber: number;
  numberOfPages: number;
  previousPagePath?: string;
  nextPagePath?: string;
}

const Index: React.FC<BlogIndexProps> = ({ data, pageContext }) => {
  const posts = data.allGhostPost.edges.map(e => e.node);
  const postList = posts.map(post => <PostCard key={post.id} {...post} />);
  const isFirstPage = pageContext.pageNumber === 0;
  if (isFirstPage)
    postList.splice(3, 0, <SubscribeBanner key="blog-main-subscribe" />);

  return (
    <BlogLayout title="Fidel API in Focus">
      <ul
        className={`blog-post-list${isFirstPage ? ' first-page' : ''}`}
        data-testid="blog-post-list"
      >
        {postList}
      </ul>
      <Pagination pageContext={pageContext} />
    </BlogLayout>
  );
};

export const allPostQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
  }
`;

export default Index;
